<template>
	<v-dialog v-model="model" persistent scrollable max-width="60vw" max-height="50vh" @close="$emit('close')">
		<v-card>
			<v-card-title class="secondary">
				<v-layout fluid align-center white--text>
					<v-flex v-t="'client_workspace.task.import.example'" headline></v-flex>
					<v-spacer></v-spacer>
					<v-btn icon @click="$emit('close')">
						<v-icon class="white--text">close</v-icon>
					</v-btn>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-data-table :headers="headers" :items="items" class="elevation-1 scroll-y fill-height">
						<template v-slot:items="props">
							<td>{{ props.item.code }}</td>
							<td>{{ props.item.date }}</td>
							<td>{{ props.item.account_title }}</td>
							<td>{{ props.item.reference }}</td>
							<td>{{ props.item.entry_title }}</td>
							<td>{{ props.item.debit }}</td>
							<td>{{ props.item.credit }}</td>
						</template>
					</v-data-table>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-layout row justify-end align-center>
					<v-btn flat color="primary" @click="copyToClipboard"> {{ $t('tasks.import.copy_example') }}</v-btn>
				</v-layout>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionImportExcelExample',
	mixins: [TasksManagerModuleGuard],
	props: {
		show: {
			required: true,
			type: Boolean
		}
	},
	data: function () {
		return {
			headers: [
				{
					text: this.$t('tasks.import.table.headers.code'),
					align: 'left',
					sortable: true,
					value: 'code'
				},
				{
					text: this.$t('tasks.import.table.headers.date'),
					align: 'left',
					sortable: true,
					value: 'date'
				},
				{
					text: this.$t('tasks.import.table.headers.account_title'),
					align: 'left',
					sortable: true,
					value: 'account_title'
				},
				{
					text: this.$t('tasks.import.table.headers.reference'),
					align: 'left',
					sortable: true,
					value: 'reference'
				},
				{
					text: this.$t('tasks.import.table.headers.entry_title'),
					align: 'left',
					sortable: true,
					value: 'entry_title'
				},
				{
					text: this.$t('tasks.import.table.headers.debit'),
					align: 'left',
					sortable: true,
					value: 'debit'
				},
				{
					text: this.$t('tasks.import.table.headers.credit'),
					align: 'left',
					sortable: true,
					value: 'credit'
				}
			],
			items: [
				{
					code: 'A1',
					date: '07/07/2018',
					account_title: 'BDD',
					reference: 'Réf Echo',
					entry_title: 'Exp',
					debit: '80',
					credit: '20'
				},
				{
					code: 'A2',
					date: '07/08/2018',
					account_title: 'ANO',
					reference: 'Réf Echo',
					entry_title: 'Exp',
					debit: '73',
					credit: '47'
				},
				{
					code: 'A3',
					date: '07/09/2018',
					account_title: 'HAG',
					reference: 'Réf Echo',
					entry_title: 'Exp',
					debit: '210',
					credit: '500'
				},
				{
					code: 'A4',
					date: '07/10/2018',
					account_title: 'ITO',
					reference: 'Réf Echo',
					entry_title: 'Exp',
					debit: '850',
					credit: '230'
				}
			]
		}
	},
	computed: {
		model: {
			get: function () {
				return this.show
			},
			set: function () {
				// Do nothing
			}
		}
	},
	methods: {
		copyToClipboard: function () {
			const dataToCopy = this.items
				.map(item => {
					return Object.keys(item).reduce((str, key) => {
						return str + (item.hasOwnProperty(key) ? (str.length ? '\t' : '') + item[key] : '')
					})
				})
				.join('\n')
			this.appService.copyToClipboard(dataToCopy)
		}
	}
}
</script>
